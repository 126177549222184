*{
  color: whitesmoke;
}
.home {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .home__header {
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
    align-items: center;
  }
  
  .home__header a {
    margin-right: 20px;
    text-decoration: inherit;
    color: #f8f8f8;
    font-size: 15px;
  }
  
  .home__header a:hover {
    text-decoration: underline;
  }
  
  .home__headerRight {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 13vw;
  }
  
  .home__headerRight > .MuiSvgIcon-root {
    margin-right: 20px;
    color: #f8f8f8;
  }

  .home__body {
    flex: 1;
    display: flex;
    margin-top: 10%;
    flex-direction: column;
  }
  
  .home__body > img {
    object-fit: contain;
    height: 100px;
  }
  
  .home__inputContainer {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 750px) {

    .search {
        max-width: 560px;
    }
    .home__body > img {
      object-fit: contain;
      height: 70px;
    }
    
    }
